import { Injectable } from '@angular/core';

import { BehaviorSubject, EMPTY, catchError, of, take } from 'rxjs';
import { AcorRequest, BillCorrectionApiService, SubmitAcorRequestsResp, SubmitAcorRequestsRqst } from '@xpo-ltl-2.0/sdk-billcorrection';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from './snack-bar.service';


export interface AcorSubmissionResponse{
    proNumber:string;
    message:string;
}

@Injectable({
  providedIn: 'root'
})
export class AcorRequestService {

  private readonly acorResponseSubject= new BehaviorSubject<AcorSubmissionResponse[]>([]);
  readonly acorResponse$= this.acorResponseSubject.asObservable();

  private readonly submitInProgressSubject = new BehaviorSubject<boolean>(false);
  readonly submitInProgres$ = this.submitInProgressSubject.asObservable()


  constructor(private billCorrection:BillCorrectionApiService,
    private snackBarService:SnackBarService) { }

  submitAcorRequest(requestData:AcorRequest[]){
    this.submitInProgressSubject.next(true);
    const request=new SubmitAcorRequestsRqst()
    request.acorRequests=requestData
    this.billCorrection.submitAcorRequests(request)
    .pipe(take(1),
    catchError((err)=>{
      this.snackBarService.openSnackBar(err?.error?.moreInfo[0]?.message);
      this.submitInProgressSubject.next(false);
      
      return EMPTY;
    })
  )
    .subscribe((response:SubmitAcorRequestsResp)=>{
        this.submitInProgressSubject.next(false);
        if(response?.warnings.length>0){

           const responesArr:AcorSubmissionResponse[]=[];
           response?.warnings?.forEach((res)=>{
                responesArr.push({
                  proNumber:res.fieldValue,
                  message:res?.message
                })
           })
           this.acorResponseSubject.next(responesArr);
        }else{
           this.acorResponseSubject.next([]);
        }
    })
  }
}
